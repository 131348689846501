.FormLayoutGroup--horizontal {
  padding: 12px 16px;
  display: flex;
  flex-flow: row nowrap;
}

/**
 * iOS
 */

.FormLayoutGroup--ios.FormLayoutGroup--horizontal {
  padding-left: 12px;
  padding-right: 12px;
}

.FormLayoutGroup--ios.FormLayoutGroup--removable {
  padding-left: 0;
}
