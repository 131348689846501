.Removable {
  position: relative;
  min-width: 0;
  width: 100%;
  max-width: 100%;
}

.Removable__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.Removable__action {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
}

.Removable--start .Removable__action {
  align-self: flex-start;
}

.FormItem .Removable,
.FormLayoutGroup .Removable {
  padding-left: var(--formitem_padding);
  padding-right: var(--formitem_padding);
  margin-left: calc(-1 * var(--formitem_padding));
  margin-right: calc(-1 * var(--formitem_padding));
}

.FormItem--withTop .Removable__action,
.FormItem--withTop ~ .Removable__action {
  top: 28px;
}

.FormItem--withTop .Removable--sizeY-compact .Removable__action,
.Removable--sizeY-compact .FormItem--withTop ~ .Removable__action {
  top: 26px;
}

.Cell .Removable {
  padding-right: 6px;
  box-sizing: border-box;
}

/* TODO: remove this
   (a very temporary fix to appease screenshot tests) */
.Cell.Cell--android .Removable.Removable--sizeY-regular {
  padding-right: 4px;
}

/**
 * iOS
 */
.Removable--ios {
  overflow: hidden;
}

.Removable__remove {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 15px;
  line-height: 45px;
  color: var(--white);
  background-color: var(--destructive);
  border: none;
  border-radius: 0;
}

.Removable__remove-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .Removable--ios .Removable__content,
  .Removable__remove {
    transition: transform .6s var(--ios-easing);
  }
}

.Removable__toggle {
  display: block;
  width: 48px;
  height: 48px;
  background: none;
}

.Removable__toggle-in {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
}

.Removable__toggle-in::after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: var(--destructive);
  border-radius: 50%;
}

.Removable__toggle-in::before {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 2px;
  background-color: var(--white);
}

.Removable--sizeY-compact .Removable__toggle {
  margin-top: -4px;
  margin-bottom: -4px;
}

.FormLayoutGroup .Removable--ios .Removable__content {
  flex-wrap: wrap;
}

.FormLayoutGroup .Removable--ios .FormItem--withTop ~ .Removable__offset {
  order: -1;
  display: block;
  width: 100%;
  height: 28px;
}

.FormLayoutGroup .Removable--ios.Removable--sizeY-compact .FormItem--withTop ~ .Removable__offset {
  height: 26px;
}

/**
 * ANDROID & VKCOM
 */
.FormItem .Removable--android .Removable__action,
.FormItem .Removable--vkcom .Removable__action,
.FormLayoutGroup .Removable--android .Removable__action,
.FormLayoutGroup .Removable--vkcom .Removable__action {
   margin-top: -2px;
   margin-right: -16px;
   margin-bottom: -2px;
}

.FormItem--sizeY-compact .Removable--android .Removable__action,
.FormItem--sizeY-compact .Removable--vkcom .Removable__action,
.FormLayoutGroup--sizeY-compact .Removable--android .Removable__action,
.FormLayoutGroup--sizeY-compact .Removable--vkcom .Removable__action {
   margin-left: 2px;
   margin-top: -4px;
   margin-right: -14px;
   margin-bottom: -4px;
}
