.Search {
  overflow: hidden;
  font-weight: normal;
  font-family: var(--font-common);
  transform: translate3d(0, 0, 0);
  line-height: normal;
}

.Search__in {
  position: relative;
}

.Search__width {
  height: var(--search_default_height);
  white-space: nowrap;
  color: transparent;
  width: 10000px;
}

.Search__control {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 1px);
  height: 100%;
  border-radius: 10px;
}

.Search__input {
  -webkit-appearance: textfield;
  border: none;
  margin: 0;
  height: var(--search_default_height);
  position: relative;
  padding: 0 22px 0 36px;
  box-sizing: border-box;
  font-size: 17px;
  border-radius: 10px;
  max-width: 100%;
  flex-grow: 1;
  min-width: 0;
  background-color: transparent;
  z-index: 2;
  color: var(--text_primary);
}

.Search__input::-webkit-search-decoration,
.Search__input::-webkit-search-cancel-button,
.Search__input::-webkit-search-results-button,
.Search__input::-webkit-search-results-decoration {
  display: none;
}

.Search--has-after .Search__input {
  border-radius: 10px 0 0 10px;
}

.Search--has-value .Search__input,
.Search--has-icon .Search__input,
.Search--has-value .Search__placeholder,
.Search--has-icon .Search__placeholder {
  padding-right: 40px;
}

.Search--has-icon.Search--has-value .Search__input,
.Search--has-icon.Search--has-value .Search__placeholder {
  padding-right: 80px;
}

.Search__after-width {
  font-size: 17px;
  height: var(--search_default_height);
  line-height: var(--search_default_height);
  padding-left: 12px;
  padding-right: 4px;
  flex-shrink: 0;
  pointer-events: none;
  cursor: text;
}

.Search__placeholder {
  position: absolute;
  left: 0;
  width: 100%;
  cursor: text;
  z-index: 1;
  text-align: left;
  height: var(--search_default_height);
  font-size: 0;
  padding-left: 12px;
  padding-right: 22px;
  pointer-events: none;
  box-sizing: border-box;
  display: flex;
}

.Search__placeholder-in {
  height: var(--search_default_height);
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  max-width: 100%;
}

.Search__placeholder-text {
  margin-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Search--has-value .Search__placeholder-text {
  opacity: 0;
}

.Search__icons {
  position: absolute;
  right: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--search_bar_field_tint);
}

.Search__icon {
  width: 40px;
  height: var(--search_default_height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Search__after {
  position: absolute;
  left: 100%;
  top: 0;
  line-height: var(--search_default_height);
  z-index: 2;
  transform: translate(0);
  transition: transform .3s var(--ios-easing);
  cursor: default;
}

.Search--focused .Search__after,
.Search--has-value .Search__after {
  transform: translate(-100%);
}

.Search__after::before {
  position: absolute;
  right: calc(100% - 1px);
  display: block;
  height: 100%;
  width: 10px;
  content: '';
  pointer-events: none;
}

.Search__after-in {
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 17px;
  padding-left: 12px;
  padding-right: 4px;
}

.Search__after::after {
  position: absolute;
  right: 100%;
  display: block;
  height: 100%;
  width: 10px;
  content: '';
  top: 0;
  border-radius: 0 10px 10px 0;
  cursor: text;
  pointer-events: none;
}

.Group--plain .Search {
  padding-top: 4px;
}

/*
  Themes
 */

/*
  default
 */
.Search {
  background: var(--search_bar_background);
}

.Search__control {
  background-color: var(--search_bar_field_background);
}

.Search__input {
  color: var(--text_primary);
}

.Search__placeholder {
  color: var(--search_bar_field_tint);
}

.Search__after-width {
  background: var(--search_bar_field_background);
  color: var(--search_bar_field_background);
}

.Search__after {
  background: var(--search_bar_background);
  color: var(--accent);
}

.Search__after::after {
  background-color: var(--search_bar_field_background);
}

.Search__after::before {
  background-color: var(--search_bar_background);
}

/*
  header
 */
.PanelHeader .Search {
  background: var(--header_background);
}

.PanelHeader .Search__control {
  background-color: var(--header_search_field_background);
}

.PanelHeader .Search__input {
  color: var(--text_primary);
}

.PanelHeader .Search__placeholder {
  color: var(--header_search_field_tint);
}

.PanelHeader .Search__after-width {
  background: var(--header_search_field_background);
  color: var(--header_search_field_background);
}

.PanelHeader .Search__after {
  background: var(--header_background);
  color: var(--header_tint);
}

.PanelHeader .Search__after::after {
  background-color: var(--header_search_field_background);
}

.PanelHeader .Search__after::before {
  background-color: var(--header_background);
}

/**
  iOS
 */
.Search--ios {
  padding: 8px 12px;
}

/**
  Android
 */
.Search--android {
  padding: 8px 16px;
}

/**
  VKCOM
*/

.Search--vkcom,
.PanelHeader--vkcom .Search {
  background: transparent;
}

.Search--vkcom .Search__in {
  padding-top: 6px;
  padding-bottom: 6px;
}

.Search--vkcom .Search__input {
  font-size: 15px;
  line-height: 20px;
  padding-left: 40px;
}

.Search--vkcom .Search__placeholder {
  padding-left: 16px;
}

.Search--vkcom .Search__icons {
  top: 4px;
  right: calc(100% + 8px);
}

.Search--vkcom.Search--has-value .Search__input {
  padding-right: 56px;
}

.Search--vkcom.Search--has-icon.Search--has-value .Search__input {
  padding-right: 92px;
}

.Search--vkcom .Search__icon {
  width: 40px;
  height: 40px;
}

.Search--vkcom .Search__icon:hover {
  background-color: var(--background_hover);
  border-radius: 50%;
}

.Search--vkcom .Search__control {
  background-color: transparent;
}

.PanelHeader--vkcom .Search__separator {
  display: none;
}

.Search--vkcom .Search__separator {
  color: var(--input_border);
}

.Group--card .Search--vkcom {
  margin: 0 -8px;
}

.Group--card .Search--vkcom:first-child {
  margin-top: -8px;
}

.Group--card .Search--vkcom:not(:last-child) {
  margin-bottom: 8px;
}

.Group--card .Search--vkcom .Search__placeholder {
  padding-left: 20px;
}

.Group--card .Search--vkcom .Search__input {
  padding-left: 44px;
}
